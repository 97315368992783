import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnalytics } from "../../states/features/analytics/analyticsSlice";
import { CgSpinner } from "react-icons/cg";
import { FaSchool, FaUsers } from "react-icons/fa";
import {
  MdHearingDisabled,
  MdOutlineDisabledVisible,
  MdOutlineSupervisorAccount,
  MdPersonalInjury,
} from "react-icons/md";
import { TbDisabled2 } from "react-icons/tb";
import BookMetricTable from "./BookMetricTable";
import { AiOutlineDownload, AiOutlineUsergroupAdd } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const Analytics = () => {
  // dispatch
  const dispatch = useDispatch();

  // get analytics
  const { analytics, isLoading } = useSelector((state) => state.analytics);

  // download pdf
  const downloadAnalytics = () => {
    const input = document.getElementById("analytics");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("Analytics");
    });
  };

  // useEffect
  useEffect(() => {
    dispatch(getAnalytics());
  }, [dispatch]);

  return (
    <div className="md:mx-8 mt-4">
      <div className="flex items-center justify-between">
        <h3 className="font-bold">EDC e-book Analytics</h3>
        {isLoading ? null : (
          <button
            className="flex space-x-1 text-primary text-sm hover:text-blue-500 group relative"
            onClick={downloadAnalytics}>
            <AiOutlineDownload />
            <span>Download Analytics</span>
            <span className="absolute -bottom-1 left-0 w-0 h-[2px] bg-blue-300 transition-all duration-300 group-hover:w-full"></span>
          </button>
        )}
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center h-[80vh]">
          <CgSpinner size={25} className="animate-spin text-primary" />
        </div>
      ) : (
        <>
          {analytics && (
            <div className="mt-2 bg-gray-50 md:py-2 rounded-xl" id="analytics">
              <div className="flex flex-wrap lg:flex-nowrap justify-between px-4 ">
                <div className="bg-white  h-44 rounded-xl w-full lg:w-[340px] p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="font-bold text-black">Users</p>
                      <p className="text-2xl text-gray-500">
                        {analytics.numberOfusers}
                      </p>
                    </div>
                    <button
                      type="button"
                      style={{ backgroundColor: "var(--primary)" }}
                      className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4">
                      <FaUsers />
                    </button>
                  </div>
                  <p className="pt-5 text-gray-500">Total number Users</p>
                </div>

                <div className="flex m-3 flex-wrap justify-center space-x-4 items-center">
                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4 pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "#03C9D7",
                        backgroundColor: "#E5FAFB",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                      <MdOutlineSupervisorAccount />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {analytics?.numberOfMaleUsers}
                      </span>
                      <span className={`text-sm text-[#03C9D7] ml-2`}>
                        {Math.round(
                          (analytics?.numberOfMaleUsers /
                            analytics?.numberOfusers) *
                            100
                        )}
                        %
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">
                      Number of Male Users
                    </p>
                  </div>

                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4 pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "rgb(255, 244, 229)",
                        backgroundColor: "rgb(254, 201, 15)",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                      <MdOutlineSupervisorAccount />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {analytics?.numberOfFemaleUsers}
                      </span>
                      <span className={`text-sm text-[#03C9D7] ml-2`}>
                        {Math.round(
                          (analytics?.numberOfFemaleUsers /
                            analytics?.numberOfusers) *
                            100
                        )}
                        %
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">
                      Number of Female Users
                    </p>
                  </div>

                  <div className="bg-white h-44  w-full md:w-52  p-8 md:p-4 pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "rgb(0, 194, 146)",
                        backgroundColor: "rgb(235, 250, 242)",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                      <MdOutlineSupervisorAccount />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {analytics?.numberOfOthers}
                      </span>
                      <span className={`text-sm text-[#03C9D7] ml-2`}>
                        {Math.round(
                          (analytics?.numberOfOthers /
                            analytics?.numberOfusers) *
                            100
                        )}
                        %
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">Other</p>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap lg:flex-nowrap justify-between px-4 ">
                <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-[340px] p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="font-bold text-black">Schools</p>
                      <p className="text-2xl text-gray-500">
                        {analytics.numberOfSchools}
                      </p>
                    </div>
                    <button
                      type="button"
                      style={{ backgroundColor: "var(--primary)" }}
                      className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4">
                      <FaSchool />
                    </button>
                  </div>
                  <p className="pt-5 text-gray-500">Total number of Schools</p>
                </div>

                <div className="flex m-3 flex-wrap justify-center space-x-4 items-center">
                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4  pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "#03C9D7",
                        backgroundColor: "#E5FAFB",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                      <BiUser />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {analytics?.numberOfParents}
                      </span>
                      <span className={`text-sm text-[#03C9D7] ml-2`}>
                        {Math.round(
                          (analytics?.numberOfParents /
                            analytics?.numberOfusers) *
                            100
                        )}
                        %
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">
                      Number of Parents
                    </p>
                  </div>

                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4  pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "rgb(0, 194, 146)",
                        backgroundColor: "rgb(235, 250, 242)",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                      <BiUser />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {analytics?.numberOfTeachers}
                      </span>
                      <span className={`text-sm text-[#03C9D7] ml-2`}>
                        {Math.round(
                          (analytics?.numberOfTeachers /
                            analytics?.numberOfusers) *
                            100
                        )}
                        %
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">
                      Number of Teacher
                    </p>
                  </div>

                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4 pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "rgb(255, 244, 229)",
                        backgroundColor: "rgb(254, 201, 15)",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                      <AiOutlineUsergroupAdd />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {analytics?.numberOfStudents}
                      </span>
                      <span className={`text-sm text-[#03C9D7] ml-2`}>
                        {Math.round(
                          (analytics?.numberOfStudents /
                            analytics?.numberOfusers) *
                            100
                        )}
                        %
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">
                      Number of Students
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-wrap lg:flex-nowrap justify-between px-4 ">
                <div className="bg-white h-44 rounded-xl w-full lg:w-[340px] p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
                  <div className="flex justify-between items-center">
                    <div>
                      <p className="font-bold text-black">
                        Users with Impairment
                      </p>
                      <p className="text-2xl text-gray-500">
                        {analytics.numberOfWithDisability}
                      </p>
                    </div>
                    <button
                      type="button"
                      style={{ backgroundColor: "var(--primary)" }}
                      className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4">
                      <TbDisabled2 />
                    </button>
                  </div>
                  <p className="pt-5 text-gray-500">
                    Total Number of impaired Users{" "}
                  </p>
                </div>

                <div className="flex m-3 flex-wrap justify-center space-x-4 items-center">
                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4  pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "#03C9D7",
                        backgroundColor: "#E5FAFB",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                      <MdHearingDisabled />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {analytics?.numberOfWithDisabilityAudio}
                      </span>
                      <span className={`text-sm text-[#03C9D7] ml-2`}>
                        {Math.round(
                          (analytics?.numberOfWithDisabilityAudio /
                            analytics?.numberOfusers) *
                            100
                        )}
                        %
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">
                      Audio Impairment
                    </p>
                  </div>

                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4  pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "rgb(255, 244, 229)",
                        backgroundColor: "rgb(254, 201, 15)",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                      <MdOutlineDisabledVisible />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {analytics?.numberOfWithDisabilityVideo}
                      </span>
                      <span className={`text-sm text-[#03C9D7] ml-2`}>
                        {Math.round(
                          (analytics?.numberOfWithDisabilityVideo /
                            analytics?.numberOfusers) *
                            100
                        )}
                        %
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">
                      Video Impairment
                    </p>
                  </div>

                  <div className="bg-white h-44 w-full md:w-52  p-8 md:p-4  pt-9 rounded-2xl ">
                    <button
                      type="button"
                      style={{
                        color: "rgb(0, 194, 146)",
                        backgroundColor: "rgb(235, 250, 242)",
                      }}
                      className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl">
                      <MdPersonalInjury />
                    </button>
                    <p className="mt-3">
                      <span className="text-lg font-semibold text-gray-500">
                        {analytics?.numberOfWithDisabilityOthers}
                      </span>
                      <span className={`text-sm text-[#03C9D7] ml-2`}>
                        {Math.round(
                          (analytics?.numberOfWithDisabilityOthers /
                            analytics?.numberOfusers) *
                            100
                        )}
                        %
                      </span>
                    </p>
                    <p className="text-sm text-gray-500  mt-1">Other</p>
                  </div>
                </div>
              </div>

              <div className="bg-white mx-8 rounded-xl p-6">
                <h3 className="font-bold">Books Metrics</h3>
                {analytics.numberOfBookLikers && (
                  <BookMetricTable metrics={analytics?.numberOfBookLikers} />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Analytics;
