import API from "../../../api/api";
import {addBookPageEndpoint, getBookPagesEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all book pages
const getBookPages = async () => {
  const response = await API.get(getBookPagesEndpoint);
  return response.data;
};

// Add a book page
const addBookPage = async (data) => {
  const response = await API.post(addBookPageEndpoint, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 201) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const pageServices = {
  getBookPages,
  addBookPage,
};

export default pageServices;
