import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddBookModal from "../components/books/AddBookModal";
import BookCard from "../components/books/BookCard";
import RoundedBtn from "../components/shared/RoundedBtn";
import { getBooks } from "../states/features/books/bookSlice";
import { CgSpinner } from "react-icons/cg";

const Books = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => setOpen((prev) => !prev);

  // dispatch
  const dispatch = useDispatch();

  // redux
  const { books, isLoading, isCreated } = useSelector((state) => state.books);
  const { user } = useSelector((state) => state.auth);

  // useEfect
  useEffect(() => {
    dispatch(getBooks());
  }, [dispatch, isCreated]);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-[80vh]">
          <CgSpinner size={25} className="animate-spin text-primary" />
        </div>
      ) : (
        <div>
          <div className="px-6 mb-6 flex items-center">
            <div className="flex items-center font-bold">All Books</div>
            <div className="ml-auto">
              {user?.userRole === "superAdmin" && (
                <RoundedBtn
                  title="Add a Book"
                  moreStyles="bg-primary py-2 text-white text-sm self-center"
                  onClick={showModal}
                />
              )}
            </div>
          </div>
          <div className="px-6 flex flex-col items-center pt-14 sm:pt-0">
            <div className="grid grid-cols-12 gap-5 lg:gap-10 items-center">
              {books &&
                books.map((book) => <BookCard book={book} key={book.id} />)}
            </div>
          </div>
          <div>{<AddBookModal open={open} setOpen={setOpen} />}</div>
        </div>
      )}
    </>
  );
};

export default Books;
