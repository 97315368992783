import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { languages } from "../../constants";
import { useEffect, useState } from "react";
import PrimaryBtn from "../buttons/PrimaryBtn";
import { addBook, reset } from "../../states/features/books/bookSlice";

const AddBookModal = ({ open, setOpen }) => {
  const [image, setImage] = useState(null);
  // React Hok Form
  const { register, handleSubmit, formState } = useForm({ mode: "all" });
  const { errors } = formState;
  // handle cancel
  const handleCancel = () => {
    setOpen(false);
  };

  // handle file
  const handleChange = (e) => setImage(e.target.files[0]);

  // dispatch
  const dispatch = useDispatch();

  // redux
  const { isCreated, isCreating } = useSelector((state) => state.books);

  useEffect(() => {
    if (isCreated) {
      setOpen(false);
    }
    dispatch(reset());
  }, [isCreated, setOpen, dispatch]);

  const onSubmitForm = ({ name, author, illustrator, language, link }) => {
    const data = {
      name,
      author,
      illustrator,
      language,
      coverImage: image,
      link,
    };

    dispatch(addBook(data));
  };
  return (
    <>
      <Modal
        open={open}
        title="Add a Book"
        width={700}
        onCancel={handleCancel}
        footer={null}>
        <form className="mt-6" onSubmit={handleSubmit(onSubmitForm)}>
          <div className="w-full flex space-x-4">
            <div className="w-1/2 flex flex-col space-y-2">
              <label>Book Name:</label>
              <input
                className="text-input w-full"
                type="text"
                name="name"
                {...register("name", { required: true })}
              />
              <span className="text-red-600 text-[13px]">
                {errors.name?.type === "required" && "Book name is required"}
              </span>
            </div>
            <div className="w-1/2 flex flex-col space-y-2">
              <label>Book Author:</label>
              <input
                className="text-input w-full"
                type="text"
                name="author"
                {...register("author", { required: true })}
              />
              <span className="text-red-600 text-[13px]">
                {errors.author?.type === "required" &&
                  "Book author is required"}
              </span>
            </div>
          </div>
          <div className="w-full flex space-x-4">
            <div className="w-1/2 flex flex-col space-y-2">
              <label>Illustrator:</label>
              <input
                className="text-input w-full"
                type="text"
                name="illustrator"
                {...register("illustrator", { required: true })}
              />
              <span className="text-red-600 text-[13px]">
                {errors.illustrator?.type === "required" &&
                  "Illustrator is required"}
              </span>
            </div>
            <div className="flex flex-col w-1/2">
              <label>Book Language</label>
              <select
                className="mt-2 block w-full px-2 py-[5px] border-gray-400 rounded border-[1.5px] focus:border-blue-400 hover:border-blue-400 text-gray-900  focus:outline-none  bg-transparent mb-2 hover:duration-300"
                name="language"
                {...register("language", { required: true })}>
                <option disabled value="Select Language">
                  Select Language
                </option>
                {languages.map((language) => (
                  <option key={language} value={language}>
                    {language}
                  </option>
                ))}
              </select>
              <span className="text-red-600 text-[13px]">
                {errors.language?.type === "required" &&
                  "Book language is required"}
              </span>
            </div>
          </div>
          <div className="w-full flex space-x-4 mt-2">
            <div className="w-1/2 flex flex-col space-y-2">
              <label>Book Link:</label>
              <input
                className="text-input w-full"
                type="text"
                name="link"
                {...register("link", { required: true })}
              />
              <span className="text-red-600 text-[13px]">
                {errors.link?.type === "required" && "Book link is required"}
              </span>
            </div>
            <div className="w-1/2 flex flex-col space-y-2">
              <label>Book Cover Image:</label>
              <input
                className="mt-2 block w-full px-2 py-[4px] bg-gray-200 rounded mb-2 hover:duration-300"
                type="file"
                name="image"
                accept="image/*"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mt-4">
            <PrimaryBtn
              title="Add Book"
              moreStyles="bg-primary text-white text-sm"
              isLoading={isCreating}
              type="submit"
              disabled={isCreating}
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default AddBookModal;
