import { BiAnalyse } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { HiUsers } from "react-icons/hi";
import { MdOutlineManageAccounts } from "react-icons/md";
import { SiBookstack } from "react-icons/si";

export const links = [
  {
    title: "Dashboard",
    links: [
      {
        id: 1,
        name: "Analytics",
        label: "analytics",
        icon: <BiAnalyse size={20} />,
      },
      {
        id: 2,
        name: "Books",
        label: "books",
        icon: <SiBookstack size={20} />,
      },
      {
        id: 3,
        name: "All Users",
        label: "users",
        icon: <FaUsers size={20} />,
      },
      {
        id: 4,
        name: "Staff",
        label: "staff",
        icon: <HiUsers size={20} />,
      },

    ],
  },
  {
    title: "Others",
    links: [
      {
        id: 5,
        name: "Account",
        label: "account",
        icon: <MdOutlineManageAccounts size={20} />,
      },
    ],
  },
];
export const staffLinks = [
  {
    title: "Dashboard",
    links: [
      {
        id: 1,
        name: "Analytics",
        label: "analytics",
        icon: <BiAnalyse size={20} />,
      },
      {
        id: 2,
        name: "Books",
        label: "books",
        icon: <SiBookstack size={20} />,
      },
      {
        id: 3,
        name: "All Users",
        label: "users",
        icon: <FaUsers size={20} />,
      },
    ],
  },
  {
    title: "Others",
    links: [
      {
        id: 4,
        name: "Account",
        label: "account",
        icon: <MdOutlineManageAccounts size={20} />,
      },
    ],
  },
];

// local storage constants
export const lsUser = "edc_user_data";

// endpoint constants
export const loginEndpoint = "/v1/api/auth/login";
export const forgotpwEndpoint = "";
export const getStaffEndpoint = "/v1/api/manage/fetch-our-Employees";
export const approveEndpoint = "/v1/api/manage/approve-user";
export const getUsersEndpoint = "/v1/api/manage/get-staffs";
export const getOneUserEndpoint = "/v1/api/manage/get-one-user"
export const registerStaffEndpoint = "/v1/api/auth/register";
export const resetpwEndpoint = "";
export const changepwEndpoint = "";
export const categoriesEndpoint = "/v1/api/manage/get-categories";
export const addCategoryEndpoint = "/v1/api/manage/add-category";
export const levelsEndpoint = "/v1/api/manage/get-levels";
export const addLevelEndpoint = "/v1/api/manage/add-level";
export const addBookEndpoint = "/v1/api/manage/add-book";
export const getBooksEndpoint = "/v1/api/manage/get-books";
export const getBookEndpoint = "/v1/api/manage/get-book";
export const addBookPageEndpoint = "/v1/api/manage/add-page";
export const getBookPagesEndpoint = "/v1/api/manage/get-pages";
export const analyticsEndpoint = "v1/api/manage/dashboard-Analytics";

export const gender = ["Male", "Female", "Choose not to disclose", "Other"];

export const languages = ["English", "French", "Swahili", "Kinyarwanda"];

export const pagesData = [
  {
    id: 1,
    title: "The Man and The Little Cat",
    body: `One day, an old man was having a stroll in the forest when he suddenly saw a little cat stuck in a hole. The poor animal was struggling to get out. So, he gave him his hand to get him out. But the cat scratched his hand with fear. The man pulled his hand screaming with pain. But he did not stop; he tried to give a hand to the cat again and again.

    Another man was watching the scene, screamed with surprise, “For god sakes! Stop helping this cat! He’s going to get himself out of there”. The other man did not care about him, he just continued saving that animal until he finally succeeded, And then he walked to that man and said, “Son, it is cat’s Instincts that makes him scratch and to hurt, and it is my job to love and care”.`,
    component: "",
  },
  {
    id: 2,
    title: "Continuation...",
    body: `Catsy swung the car and moved towards Gyan Owl’s house. After reaching the owl’s house, she started loudly ringing the horn of the car, ‘Pe Pe Pe Poooooo.’ The owl came out from inside and said, “Don’t shout! Don’t shout! I am coming”“It’s me, Catsy your sweet cat meow meow,” said Catsy. “Oh my dear Catsy, you came here after a long time. What’s the matter?” The Gyan owl said. Catsy asks, “I don’t know the way to Milky Cow’s house. Can you tell me the way?” The owl said, “Oh wow! You are going to Milky’s house. You are so good I have to give some stuff to Milky.”`,
    component: "",
  }
];
