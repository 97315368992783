import { AiOutlineClose } from "react-icons/ai";
import { Empty } from "antd";

import "./styles.css";

const MostLikedBooks = ({ openModal, setOpenModal }) => {
  return (
    <div
      className={`${
        openModal
          ? "bg-black/30 fixed z-50 top-0 bottom-0 right-0 -left-2 w-full h-screen"
          : ""
      }  `}>
      <div className="relative">
        <div
          className={`fixed top-0 bottom-0 right-0 h-screen  duration-500 ease-linear transition-all bg-white w-[300px] sm:w-[400px] p-8 z-50 ${
            openModal ? "translate-x-0" : "translate-x-full"
          }`}>
          <AiOutlineClose
            size={25}
            className="absolute top-4 right-4 cursor-pointer"
            onClick={() => setOpenModal((prev) => !prev)}
          />

          <div className="mt-2">
            <h2 className="font-bold">Most Liked Books</h2>
            <div className="mt-[30vh]">
              <Empty />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MostLikedBooks;
