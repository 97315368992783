import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersServices from "./usersServices";

const usersSlice = createSlice({
	name: "users",
	initialState: {
		users: [],
		isError: false,
		isSuccess: false,
		isApproved: false,
		isLoading: false,
		isApproving: false,
		message: "",
	},
	reducers: {
		reset: (state) => {
			state.isLoading = false;
			state.isApproving = false;
			state.isSuccess = false;
			state.isApproved = false;
			state.isError = false;
			state.message = "";
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getUsers.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getUsers.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.users = payload;
			})
			.addCase(getUsers.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.isError = true;
				state.message = payload;
				state.users = [];
			})
			.addCase(approveUser.pending, (state) => {
				state.isApproving = true;
			})
			.addCase(approveUser.fulfilled, (state, { payload }) => {
				state.isApproving = false;
				state.isSuccess = true;
				state.isApproved = true;
				state.message = payload;
			})
			.addCase(approveUser.rejected, (state, { payload }) => {
				state.isApproving = false;
				state.isError = true;
				state.message = payload;
			});
	},
});

// Get all Users
export const getUsers = createAsyncThunk(
	"getUsers",
	async (thunkAPI) => {
		try {
			return await usersServices.getUsers();
		} catch (error) {
			const message =
				error.response.data.errors ||
				error.response.data.status ||
				error.response.data.message;
			error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);

// approve user
export const approveUser = createAsyncThunk(
	"user/approve",
	async (id, thunkAPI) => {
		try {
			return await usersServices.approve(id);
		} catch (error) {

			const message =
				error.response.data.errors ||
				error.response.data.status ||
				error.response.data.message;
			error.toString();
			return thunkAPI.rejectWithValue(message);
		}
	}
);


export const { reset } = usersSlice.actions;

export default usersSlice.reducer;
