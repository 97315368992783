import React from "react";
import { useDispatch } from "react-redux";

import { logout } from "../states/features/auth/authSlice";
import { lsUser } from "../constants";
import { parseToken } from "../utils";

const VerifyAuth = () => {
  // Get user from local storage
  const user = JSON.parse(localStorage.getItem(lsUser));

  // initialize dispatch
  const dispatch = useDispatch();

  // Check token if valid
  if (user) {
    const decodedJwt = parseToken(user?.token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      dispatch(logout());
    }
  }
  return <></>;
};
export default VerifyAuth;
