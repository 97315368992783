import React, { useEffect } from "react";
import { CgSpinner } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../states/features/users/usersSlice";
import UsersTable from "./UsersTable";

const Users = () => {
  // dispatch
  const dispatch = useDispatch();

  // users
  const { users, isLoading, message, isApproved } = useSelector((state) => state.users);

  // useEffect
  useEffect(() => {
    dispatch(getUsers());
  }, [message, dispatch, isApproved]);

  return (
    <div className="px-8 pt-4">
      <h3 className="font-bold text-md">All Users</h3>
      {users.length === 0 && isLoading ? (
        <div className="min-h-[70vh] flex items-center justify-center">
          <CgSpinner size={25} className="animate-spin text-primary" />
        </div>
      ) : (
        <div className="min-h-[80vh]">
          <UsersTable staff={users && users} />
        </div>
      )}
    </div>
  );
};

export default Users;
