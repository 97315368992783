import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBook } from "../../states/features/books/bookSlice";
import { CgSpinner } from "react-icons/cg";

import { IoIosArrowBack } from "react-icons/io";
import { placeholder } from "../../assets";
import { logout } from "../../states/features/auth/authSlice";
import Iframe from "react-iframe";
import DateFormater from "../shared/DateFormater";
import { AiFillLike } from "react-icons/ai";
import { BiCheckDouble } from "react-icons/bi";

const BookContainer = () => {
  // get id
  const { id } = useParams();

  // dispatch
  const dispatch = useDispatch();

  // navigate
  const navigate = useNavigate();

  // redux
  const { book, isLoading } = useSelector((state) => state.books);

  // get book by id
  useEffect(() => {
    dispatch(getBook(id));
  }, [dispatch, id]);

  // logo out
  const logoutUser = () => {
    dispatch(logout());
  };

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <CgSpinner size={25} className="animate-spin text-primary" />
        </div>
      ) : (
        <main className="w-[80%] min-h-screen mx-auto">
          <header className="h-[60px] flex items-center justify-between border-b-[1.5px] border-primary">
            <button
              className="flex items-center space-x-2"
              onClick={() => navigate(-1)}>
              <span className="bg-primary p-1 text-white rounded-lg">
                <IoIosArrowBack size={20} />
              </span>
              <span className="text-primary">Back to Dashboard</span>
            </button>
            <button
              className="bg-primary px-4 text-center rounded-full text-[13px] text-white inline-flex items-center whitespace-nowrap py-[4px]"
              onClick={logoutUser}>
              Sign out
            </button>
          </header>
          {book && (
            <div className="mt-8 flex space-y-2 md:space-x-8">
              <div className="w-full sm:w-4/12 shadow shadow-blue-100 rounded-xl">
                <div className="w-fit mx-auto py-6">
                  <div className="w-[200px] sm:w-[300px]  cursor-pointer text-xl hover:scale-[1.02] transition-all ease-in-out duration-300 rounded-lg overflow-hidden">
                    <img
                      className="w-full h-full object-cover object-center"
                      src={
                        book?.coverImage === null
                          ? placeholder
                          : book?.coverImage
                      }
                      alt="poster"
                    />
                  </div>
                  <div className="mt-4 space-y-2">
                    <h3 className="font-[Gordita-Bold]">{book?.name}</h3>
                    <p className="font-bold">
                      By: <span className="text-gray-500">{book?.author}</span>
                    </p>
                    <p className="font-bold">
                      Language:{" "}
                      <span className="text-gray-500">{book?.language}</span>
                    </p>
                    <p className="font-bold">
                      Illustrator:{" "}
                      <span className="text-gray-500">{book?.illustrator}</span>
                    </p>
                    <div className="flex items-center justify-between">
                      <h3 className="flex items-center space-x-2">
                        <AiFillLike color="rgb(250 204 21)" />{" "}
                        <span className="font-bold">likes:</span>
                        <span className="text-sm">{book?.likes}</span>
                      </h3>
                      <h3 className="flex items-center space-x-2">
                        <BiCheckDouble color="#15e615" />{" "}
                        <span className="font-bold">reads:</span>
                        <span className="text-sm">{book?.reads}</span>
                      </h3>
                    </div>
                    <div className="flex items-center space-x-2">
                      <p className="font-bold">Created Date: </p>
                      <DateFormater value={book?.created_at} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full sm:w-8/12 font-[Gordita-Bold]">
                <h2 className="mb-10">{book?.name}</h2>
                <Iframe
                  url={book?.link}
                  width="100%"
                  height="500px"
                  id=""
                  className=""
                  display="block"
                  position="relative"
                />
              </div>
            </div>
          )}
        </main>
      )}
    </>
  );
};

export default BookContainer;
