import { useState } from "react";
import Body from "../components/admin/Body";
import Navbar from "../components/shared/Navbar";
import Sidebar from "../components/shared/Sidebar";

const AdminDashboard = () => {
  // local states
  const [index, setIndex] = useState(1);
  const [screenSize, setScreenSize] = useState(undefined);
  const [activeMenu, setActiveMenu] = useState(true);

  return (
    <div className="flex relative">
      {activeMenu ? (
        <div className="w-72 fixed sidebar bg-white ">
          <Sidebar
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            screenSize={screenSize}
            index={index}
            setIndex={setIndex}
          />
        </div>
      ) : (
        <div className="w-0">
          <Sidebar
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            screenSize={screenSize}
            index={index}
            setIndex={setIndex}
          />
        </div>
      )}
      <div
        className={
          activeMenu
            ? "bg-main-bg min-h-screen md:ml-72 w-full overflow-hidden "
            : "bg-main-bg  w-full min-h-screen flex-2 "
        }>
        <div className="fixed md:static bg-main-bg navbar w-full ">
          <Navbar
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
            screenSize={screenSize}
            setScreenSize={setScreenSize}
          />
        </div>
        <div className="my-8">
          <Body index={index} />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
