import { Empty } from "antd";
import { useMemo } from "react";
import SimpleTable from "../shared/SimpleTable";
import Likes from "./Likes";
import Reads from "./Reads";

const BookMetricTable = ({ metrics }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Book Name",
        accessor: "name",
      },
      {
        Header: "Likes",
        accessor: "likes",
        Cell: Likes
      },
      {
        Header: "Reads",
        accessor: "reads",
        Cell: Reads
      },   
    ],
    []
  );

  // Store data in the memo
  const data = useMemo(() => metrics, [metrics]);

  return (
    <>
      {data && data.length === 0 ? (
        <div className="min-h-[70vh] flex items-center justify-center">
          <Empty />
        </div>
      ) : (
        <div>
          <SimpleTable columns={columns} data={data} />
        </div>
      )}
    </>
  );
};

export default BookMetricTable;
