import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Admin, Book, Forogotpw, Login, NotFound, Staff } from "./pages";

// css
import "./App.css";
import { AdminRoutes, OfficerRoutes, SharedProtectedRoutes } from "./outlets";
import VerifyAuth from "./helpers/VerifyAuth";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<AdminRoutes />}>
            <Route path="/edc/dashboard/admin" element={<Admin />} />
          </Route>
          <Route element={<OfficerRoutes />}>
            <Route path="/edc/dashboard/staff" element={<Staff />} />
          </Route>
          <Route element={<SharedProtectedRoutes />}>
            <Route path="/book/:id" element={<Book />} />
          </Route>
          <Route path="/" element={<Login />} />
          <Route path="/forgot-password" element={<Forogotpw />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
      <VerifyAuth />
    </>
  );
};

export default App;
