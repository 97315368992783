import { classNames } from "../../utils";

const StatusPill = ({ value }) => {
  const status = value === "pending" ? "pending" : "approved";

  return (
    <span
      className={classNames(
        "px-3 py-1 leading-wide font-bold text-xs rounded-full shadow-sm",
        status === "approved"
          ? "bg-green-100 text-green-800"
          : "bg-yellow-100 text-yellow-800"
      )}>
      {status}
    </span>
  );
};

export default StatusPill;
