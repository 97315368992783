import { Modal } from "antd";
import { CgSpinner } from "react-icons/cg";

const LoadingModal = ({ isApproving }) => {
  return (
    <Modal
      open={isApproving}
      footer={null}
      closable={false}
      title={null}
      mask={true}>
      <div className="my-4 flex flex-col sm:flex-row space-x-4 justify-center">
        <CgSpinner
          size={25}
          className="animate-spin duration-200 text-primaryDark"
        />
        <p className="font-bold">Approving User</p>
      </div>
    </Modal>
  );
};

export default LoadingModal;
