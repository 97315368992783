// redux
import { useDispatch } from "react-redux";
import { approveUser } from "../../states/features/users/usersSlice";
const ApproveAction = ({ value }) => {
  // useDispatch hook
  const dispatch = useDispatch();

  // handle approve function
  const handleApprove = () => {
    dispatch(approveUser(value));
  };

  return (
    <>
      <div className="flex gap-3">
        <div
          className="bg-green-100 text-green-800 flex flex-row items-center justify-center gap-2 cursor-pointer hover:text-primaryDark px-3 py-1 leading-wide font-bold text-xs rounded-full shadow-sm w-[90px]"
          onClick={handleApprove}>
          <p>approve</p>
        </div>
      </div>
    </>
  );
};

export default ApproveAction;
