import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { gender } from "../../../constants";
import { addStaff, reset } from "../../../states/features/staff/staffSlice";
import PrimaryBtn from "../../buttons/PrimaryBtn";

const CreateStaff = ({ setOpen }) => {
  const { register, handleSubmit, formState } = useForm({ mode: "all" });
  const { errors } = formState;

  const { isCreating, isCreated } = useSelector((state) => state.staff);

  const dispatch = useDispatch();

  const onSubmitForm = ({
    userName,
    password,
    firstName,
    lastName,
    mobileNumber,
    address,
    sex,
    email,
  }) => {
    dispatch(
      addStaff({
        userName,
        password,
        firstName,
        lastName,
        mobileNumber,
        address,
        sex,
        email,
        userType: "staff",
      })
    );
  };

  useEffect(() => {
    if (isCreated) {
      setOpen(false);
      dispatch(reset());
    }
  }, [dispatch, isCreated, setOpen]);

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmitForm)}>
      {/* Names Section */}
      <div className="flex flex-row space-x-4">
        <div className="flex flex-col w-1/2">
          <label>Username</label>
          <input
            className="text-input w-full"
            type="text"
            name="userName"
            {...register("userName", { required: true })}
          />
          <span className="text-red-600 text-[13px] mb-2 -mt-2">
            {errors.userName?.type === "required" && "Username is required"}
          </span>
        </div>
        <div className="flex flex-col w-1/2">
          <label>Password</label>
          <input
            className="text-input w-full"
            type="password"
            name="password"
            {...register("password", { required: true })}
          />
          <span className="text-red-600 text-[13px] mb-2 -mt-2">
            {errors.password?.type === "required" && "Password is required"}
          </span>
        </div>
      </div>
      <div className="flex flex-row space-x-4">
        <div className="flex flex-col w-1/2">
          <label>First Name</label>
          <input
            className="text-input w-full"
            type="text"
            name="firstName"
            {...register("firstName", { required: true })}
          />
          <span className="text-red-600 text-[13px] mb-2 -mt-2">
            {errors.firstName?.type === "required" && "First name is required"}
          </span>
        </div>
        <div className="flex flex-col w-1/2">
          <label>Last Name</label>
          <input
            className="text-input w-full"
            type="text"
            name="lastName"
            {...register("lastName", { required: true })}
          />
          <span className="text-red-600 text-[13px] mb-2 -mt-2">
            {errors.lastName?.type === "required" && "Last name is required"}
          </span>
        </div>
      </div>

      <div className="flex flex-row space-x-4">
        <div className="flex flex-col w-1/2">
          <label>Gender</label>
          <select
            defaultValue="Select Gender"
            name="sex"
            className="text-input w-full"
            {...register("sex", { required: true })}>
            <option value="Select Gender" disabled>
              Select Gender
            </option>
            {gender.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <span className="text-red-600 text-[13px] mb-2 -mt-2">
            {errors.sex?.type === "required" && "Gender is required"}
          </span>
        </div>
        <div className="flex flex-col w-1/2">
          <label>Address</label>
          <input
            className="text-input w-full"
            type="text"
            name="address"
            {...register("address", { required: true })}
          />
          <span className="text-red-600 text-[13px] mb-2 -mt-2">
            {errors.address?.type === "required" && "Address is required"}
          </span>
        </div>
      </div>
      {/* Phone and Email Section */}
      <div className="flex flex-row space-x-4">
        <div className="flex flex-col w-1/2">
          <label>Email</label>
          <input
            className="text-input w-full"
            type="text"
            name="email"
            {...register("email", { required: true })}
          />
          <span className="text-red-600 text-[13px] mb-2 -mt-2">
            {errors.email?.type === "required" && "Email is required"}
          </span>
        </div>
        <div className="flex flex-col w-1/2">
          <label>Phone number</label>
          <input
            className="text-input w-full"
            type="text"
            name="mobileNumber"
            {...register("mobileNumber", { required: true })}
          />
          <span className="text-red-600 text-[13px] mb-2 -mt-2">
            {errors.mobileNumber?.type === "required" &&
              "Phone number is required"}
          </span>
        </div>
      </div>
      <div className="mt-3 flex">
        <PrimaryBtn
          type="submit"
          moreStyles="bg-primary ml-auto"
          title={"Add Staff"}
          isLoading={isCreating}
        />
        ,
      </div>
    </form>
  );
};

export default CreateStaff;
