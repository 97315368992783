import React from 'react'
import { AiFillLike } from 'react-icons/ai';

const Likes = ({value}) => {
  return (
     <div className="flex items-center">
     <div className="bg-gray-100 p-2 w-fit rounded-full">
       <AiFillLike color="rgb(250 204 21)"/>
     </div>
     <p className="ml-2">{value}</p>
   </div>
  )
}

export default Likes