import { useRef, useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
import { SortDownIcon, SortIcon, SortUpIcon } from "./Icons";
import { FaSearch } from "react-icons/fa";
import { classNames } from "../../utils";
import { TbTableExport } from "react-icons/tb";
import { DownloadTableExcel } from "react-export-table-to-excel";

import "./styles.css";
import { useSelector } from "react-redux";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  tableRef,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const { user } = useSelector((state) => state.auth);


  return (
    <>
      <div className="flex justify-between items-center w-full">
        <div className="flex justify-between items-center py-[5px] px-4 w-[260px] rounded-full border-gray-200 border-[2px]">
          <input
            type="text"
            className="outline-none w-full bg-transparent"
            value={value || ""}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`Search - ${count} users available`}
          />
          <FaSearch size={15} className="text-gray-300" />
        </div>
        {user?.userRole === "superAdmin" &&  <DownloadTableExcel
          filename="All Users"
          sheet="Users"
          currentTableRef={tableRef.current}>
          <button className="flex space-x-1 text-primary text-sm hover:text-blue-500 group relative z-0">
            <TbTableExport />
            <span>Export</span>
            <span className="absolute -bottom-1 left-0 w-0 h-[2px] bg-blue-300 transition-all duration-300 group-hover:w-full"></span>
          </button>
        </DownloadTableExcel>}
       
      </div>
    </>
  );
}

// Role Pill
export function RolePill({ value }) {
  const session = value === "newSession" ? "new" : "follow-up";

  return (
    <span
      className={classNames(
        "px-3 py-1 leading-wide font-bold text-xs rounded-full shadow-sm",
        session === "new"
          ? "bg-green-100 text-green-800"
          : "bg-yellow-100 text-yellow-800"
      )}>
      {session}
    </span>
  );
}

// Table
function UserTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination // new
  );

  const tableRef = useRef(null);

  // Render the UI for your table
  return (
    <>
      <div className="sm:flex sm:space-x-2">
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          tableRef={tableRef}
        />
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div className="mt-2 sm:mt-0" key={column.id}>
                {column.render("Filter")}
              </div>
            ) : null
          )
        )}
      </div>
      {/* table */}
      <div className="mt-8 flex flex-col" ref={tableRef}>
        <div className="-my-2 custom-overflow -mx-4 sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-8">
            <div className="border-b border-gray-300">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-floor">
                <thead className="bg-floor">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        <th
                          scope="col"
                          className="group px-6 py-3 text-left text-sm font-lg text-gray-600  tracking-wider"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}>
                          <div className="flex items-center justify-between">
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortDownIcon className="w-4 h-4 text-gray-500" />
                                ) : (
                                  <SortUpIcon className="w-4 h-4 text-gray-500" />
                                )
                              ) : (
                                <SortIcon className="w-4 h-4 text-gray-500 opacity-0 group-hover:opacity-100" />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200">
                  {page.map((row, i) => {
                    // new
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              className="px-6 py-2 whitespace-nowrap"
                              role="cell">
                              {cell.column.Cell.name === "defaultRenderer" ? (
                                <div className="text-[14px] text-gray-700">
                                  {cell.render("Cell")}
                                </div>
                              ) : (
                                cell.render("Cell")
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserTable;
