import React, { useEffect, useState } from "react";
import {
  AiFillLike,
  AiOutlineLogout,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from "react-icons/ai";
import { Tooltip } from "antd";
import MostLikedBooks from "../books/MostLikedBooks";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../../states/features/auth/authSlice";
import { HiUserCircle } from "react-icons/hi";

// Navbutton component
const NavButton = ({ title, customFunc, icon }) => (
  <Tooltip placement="bottom" title={title} color={"#23408f"}>
    <button
      type="button"
      onClick={() => customFunc()}
      className="relative text-xl rounded-full p-3 hover:bg-gray-200">
      {icon}
    </button>
  </Tooltip>
);

const Navbar = ({
  currentColor,
  activeMenu,
  setActiveMenu,
  screenSize,
  setScreenSize,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  // redux state
  const { user } = useSelector((state) => state.auth);
  
  // logout
  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
  };

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [setActiveMenu, screenSize]);

  // useEffect
  useEffect(() => {
    if (openModal) {
      document.body.classList.add("block-scroll");
    } else {


      document.body.classList.remove("block-scroll");
    }
  }, [openModal, setOpenModal]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div className="bg-white flex justify-between py-2 px-6 sm:px-4 relative border-b-[1px] border-floor">
      <NavButton
        customFunc={handleActiveMenu}
        color={currentColor}
        icon={
          activeMenu ? (
            <AiOutlineMenuFold className="text-[#23408f]" />
          ) : (
            <AiOutlineMenuUnfold className="text-[#23408f]" />
          )
        }
      />
      <div className="flex items-center space-x-2 relative">
        <NavButton
          title="Mostly Liked Books"
          customFunc={() => setOpenModal((prev) => !prev)}
          color={currentColor}
          icon={<AiFillLike className="text-yellow-400" />}
        />
        <div className="flex items-center space-x-2 p-1 rounded-lg px-2">
          <HiUserCircle size={35} className="text-gray-400" />
          <p>
            <span className="text-dark text-[14px]">Hi,</span>{" "}
            <span className="text-dark font-bold ml-1 text-[14px]">
              {user?.userDetails && user?.userDetails?.lastName}
            </span>
          </p>
        </div>
        <div>
          <NavButton
            title="Logout"
            customFunc={onLogout}
            color={currentColor}
            icon={<AiOutlineLogout className="text-red-400" />}
          />
        </div>

        <MostLikedBooks openModal={openModal} setOpenModal={setOpenModal} />
      </div>
    </div>
  );
};

export default Navbar;
