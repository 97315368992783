import API from "../../../api/api";
import { analyticsEndpoint } from "../../../constants";

// Get all analytics
const getAnalytics = async () => {
    const response = await API.get(analyticsEndpoint);
    return response.data;
};

const analyticsServices = {
    getAnalytics
};

export default analyticsServices;
