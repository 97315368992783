import API from "../../../api/api";
import {
  addBookEndpoint,
  getBookEndpoint,
  getBooksEndpoint,
} from "../../../constants";
import { notification } from "../../../utils";

// Get all books
const getBooks = async () => {
  const response = await API.get(getBooksEndpoint);
  return response.data;
};

// Get a single book
const getBook = async (id) => {
  const response = await API.get(`${getBookEndpoint}/${id}`);
  return response.data;
};

// Add a book
const addBook = async (data) => {
  const response = await API.post(addBookEndpoint, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  if (response.status === 201) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const bookServices = {
  getBooks,
  getBook,
  addBook,
};

export default bookServices;
