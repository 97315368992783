import { IoIosAdd } from "react-icons/io";
import { ImSpinner2 } from "react-icons/im";

const PrimaryBtn = ({
  title,
  onClick,
  type,
  disabled,
  isLoading,
  moreStyles,
}) => {
  return (
    <>
      <button
        onClick={onClick}
        type={type}
        disabled={disabled}
        className={`${moreStyles} outline-none px-3 rounded-md focus:ring-[1.5px] focus:ring-primaryLight focus:ring-offset-1 h-9 bg-primaryDark ${
          isLoading ? null : "hover:bg-opacity-90"
        } ${isLoading ? "disabled:cursor-not-allowed" : null} text-white px-3`}
      >
        {isLoading ? (
          <span className="flex justify-center items-center">
            Adding 
            <ImSpinner2 size={20} className="animate-spin ml-2" />
          </span>
        ) : (
          <div className="flex items-center">
            <IoIosAdd size={20} className="-ml-1" />
            <span>{title}</span>
          </div>
        )}
      </button>
    </>
  );
};

export default PrimaryBtn
