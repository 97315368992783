import { Link } from "react-router-dom";
import { Result } from "antd";
import RoundedBtn from "../components/shared/RoundedBtn";

const NotFound = () => (
  <>
    <div className="h-screen flex flex-col justify-center">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <RoundedBtn
              title="Go Back Home"
              moreStyles="bg-primary text-white py-[8px]"
            />
          </Link>
        }
      />
    </div>
  </>
);
export default NotFound;
