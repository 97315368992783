import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

// Wrapper component for protect routes
const OfficerRoutes = () => {
  const { user } = useSelector((state) => state.auth);

  if (user) {
    if (user?.userRole === "staff") {
      return <Outlet />;
    } else {
      return <Navigate to="/unauthorized" />;
    }
  } else {
    return <Navigate to="/" />;
  }
};

export default OfficerRoutes;
