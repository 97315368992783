import API from "../../../api/api";
import { getStaffEndpoint, registerStaffEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all Staff
const getStaff = async () => {
  const response = await API.get(getStaffEndpoint);
  return response.data;
};

// Add Staff
const addStaff = async (data) => {
  const response = await API.post(registerStaffEndpoint, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (response.status === 201) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const staffServices = {
  getStaff,
  addStaff,
};

export default staffServices;
