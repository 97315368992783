import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import staffServices from "./staffServices";

const staffSlice = createSlice({
  name: "staff",
  initialState: {
    staff: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    isCreating: false,
    isCreated: false,
    message: "",
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isCreating = false;
      state.isSuccess = false;
      state.isCreated = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStaff.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isCreated = false;
        state.staff = payload;
      })
      .addCase(getStaff.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.staff = [];
      })
      .addCase(addStaff.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(addStaff.fulfilled, (state, { payload }) => {
        state.isCreating = false;
        state.isSuccess = true;
        state.isCreated = true;
        state.isError = false;
        state.message = payload;
      })
      .addCase(addStaff.rejected, (state, { payload }) => {
        state.isCreating = false;
        state.isError = true;
        state.message = payload;
        state.staff = [];
      });
  },
});

// Get all Staffs
export const getStaff = createAsyncThunk(
  "hospitals/staff/dashboard",
  async (thunkAPI) => {
    try {
      return await staffServices.getStaff();
    } catch (error) {
      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Add Staff
export const addStaff = createAsyncThunk(
  "staff/addStaff",
  async (staffData, thunkAPI) => {
    try {
      return await staffServices.addStaff(staffData);
    } catch (error) {

      const message =
        error.response.data.errors ||
        error.response.data.status ||
        error.response.data.message;
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const { reset } = staffSlice.actions;

export default staffSlice.reducer;
