import { Link } from "react-router-dom";
import { placeholder } from "../../assets";

function BookCard({ book }) {
  return (
    <Link
      className="col-span-12 md:col-span-6 lg:col-span-3 bg-floor rounded-xl overflow-hidden"
      to={`/book/${book.id}`}
    >
      <div className="w-[17em] sm:w-[250px] h-[300px]  cursor-pointer text-xl hover:scale-[1.02] transition-all ease-in-out duration-300">
        <img
          className="w-full h-full object-cover object-center"
          src={book.coverImage === null ? placeholder : book.coverImage}
          alt="poster"
        />
      </div>
      <div className="p-3">
        <h3 className="font-semibold text-dark text-[15px]">{book.name}</h3>
        <small className="text-gray-500">{book.author}</small>
        <h3 className="flex items-center space-x-2">
          <span className="text-yellow-400 text-xl">&hearts;</span>{" "}
          <span className="text-sm">3</span>
        </h3>
      </div>
    </Link>
  );
}

export default BookCard;
