import React, { useEffect } from "react";
import { CgSpinner } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { getStaff } from "../../states/features/staff/staffSlice";
import StaffTable from "./StaffTable";

const Staff = () => {
  // dispatch
  const dispatch = useDispatch();

  // approved
  const { isApproved } = useSelector((state) => state.users);

  // staff
  const { staff, isLoading, message } = useSelector((state) => state.staff);

  // useEffect
  useEffect(() => {
    dispatch(getStaff());
  }, [message, dispatch, isApproved]);

  return (
    <div className="px-8 pt-4">
      <h3 className="font-bold text-md">All Staff</h3>
      {staff.length === 0 && isLoading ? (
        <div className="min-h-[70vh] flex items-center justify-center">
          <CgSpinner size={25} className="animate-spin text-primary" />
        </div>
      ) : (
        <div className="min-h-[80vh]">
          <StaffTable staff={staff && staff} />
        </div>
      )}
    </div>
  );
};

export default Staff;
