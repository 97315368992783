import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { IoMdLogIn } from "react-icons/io";
import { CgSpinner } from "react-icons/cg";
import { MdAlternateEmail } from "react-icons/md";
import { RiLockPasswordLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { fullLogo } from "../../assets";
import { login, reset } from "../../states/features/auth/authSlice";
import { notification } from "../../utils";

const LoginForm = () => {
  // React Hook Form methods
  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;

  // redux state
  const { user, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.auth
  );

  // dispatch and navigate
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // onSubmit
  const onSubmit = ({ userName, password }) => {
    dispatch(login({ userName, password }));
  };

  useEffect(() => {
    if (isError) {
      notification(message, "error", "bottomLeft");
      dispatch(reset());
    }
    if (isSuccess || user) {
      if (user?.userRole === "superAdmin") {
        navigate("/edc/dashboard/admin");
      }
      if (user?.userRole === "staff") {
        navigate("/edc/dashboard/staff");
      }
    }
  }, [isError, message, isSuccess, navigate, dispatch, user]);

  return (
    <>
      <div className="min-h-screen flex flex-col items-center justify-center bg-floor px-6 lg:px-0">
        <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
          <img
            src={fullLogo}
            alt="edc-logo"
            width={"50%"}
            className="mx-auto"
          />
          <div className="relative mt-8 h-px bg-floor">
            <div className="absolute left-0 top-0 flex justify-center w-full -mt-2">
              <span className="bg-white px-4 text-sm text-gray-600">
                Reserved for Staff Only
              </span>
            </div>
          </div>
          <div className="mt-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col mb-6">
                <label className="mb-[5px] text-xs sm:text-sm tracking-wide text-dark">
                  Username
                </label>
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                    <MdAlternateEmail size={20} />
                  </div>
                  <input
                    name="userName"
                    className="default-input"
                    placeholder="Your e-mail"
                    {...register("userName", {
                      required: true,
                    })}
                  />
                </div>
                <span className="text-[#751919] text-[12px]">
                  {errors.userName?.type === "required" &&
                    "Username is required"}
                </span>
              </div>
              <div className="flex flex-col mb-6">
                <label className="mb-[5px] text-xs sm:text-sm tracking-wide text-dark">
                  Password
                </label>
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                    <span>
                      <RiLockPasswordLine size={20} />
                    </span>
                  </div>

                  <input
                    type="password"
                    name="password"
                    className="default-input"
                    placeholder="Your Password"
                    {...register("password", { required: true })}
                  />
                </div>
                <span className="text-[#751919] text-[12px]">
                  {errors.password?.type === "required" &&
                    "Password is required"}
                </span>
              </div>

              <div className="flex items-center mb-6 -mt-4">
                <div className="flex ml-auto">
                  <Link
                    to="/forgot-password"
                    className="text-xs sm:text-sm text-secondary hover:text-primary">
                    Forgot Your Password?
                  </Link>
                </div>
              </div>

              <div className="flex w-full">
                <button
                  type="submit"
                  className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-primary hover:opacity-90 rounded py-2 w-full transition duration-150 ease-in">
                  <span className="mr-2 font-bold">Login</span>
                  <span>
                    {isLoading ? (
                      <CgSpinner
                        size={20}
                        className="animate-spin text-white"
                      />
                    ) : (
                      <IoMdLogIn size={20} />
                    )}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
