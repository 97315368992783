import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { MdAlternateEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { fullLogo } from "../../assets";

const ForgotpwForm = () => {
  // React Hook Form methods
  const { register, handleSubmit, formState } = useForm();
  const { errors } = formState;

  const dispatch = useDispatch();

  // onSubmit
  const onSubmit = ({ email }) => {
    dispatch();
  };
  return (
    <>
      <div className="min-h-screen flex flex-col items-center justify-center bg-floor px-6 lg:px-0">
        <div className="flex flex-col bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8 rounded-md w-full max-w-md">
          <img
            src={fullLogo}
            alt="edc-logo"
            width={"50%"}
            className="mx-auto"
          />
          <div className="relative mt-8 h-px bg-floor">
            <div className="absolute left-0 top-0 flex justify-center w-full -mt-4">
              <span className="px-4 text-sm text-gray-600 bg-floor rounded-full py-2">
                Enter email for a Reset Link
              </span>
            </div>
          </div>
          <div className="mt-10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col mb-6">
                <label className="mb-[5px] text-xs sm:text-sm tracking-wide text-dark">
                  E-mail
                </label>
                <div className="relative">
                  <div className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400">
                    <MdAlternateEmail size={20} />
                  </div>

                  <input
                    name="email"
                    className="default-input"
                    placeholder="Your e-mail"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i,
                    })}
                  />
                </div>
                <span className="text-[#510c0c] text-[12px]">
                  {errors.email?.type === "required" && "Email is required"}
                </span>
                <span className="text-[#510c0c] text-[12px]">
                  {errors.email?.type === "pattern" && "Email is not valid"}
                </span>
              </div>

              <div className="flex items-center mb-6 -mt-4">
                <div className="flex ml-auto">
                  <Link
                    to="/"
                    className="text-xs sm:text-sm text-secondary hover:text-primary"
                  >
                    Login
                  </Link>
                </div>
              </div>

              <div className="flex w-full">
                <button
                  type="submit"
                  className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-primary hover:opacity-90 rounded py-2 w-full transition duration-150 ease-in"
                >
                  <span className="mr-2 font-bold">Reset</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotpwForm;
