import React from "react";
import { Books } from "../../pages";
import Account from "../admin/Account";
import Analytics from "./Analytics";
import Users from "./Users";

const Body = ({ index }) => {
  return (
    <>
      {index === 1 && <Analytics />}
      {index === 2 && <Books />}
      {index === 3 && <Users />}
      {index === 4 && <Account />}
    </>
  );
};

export default Body;
