import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./features/auth/authSlice";
import staffReducer from "./features/staff/staffSlice";
import usersReducer from "./features/users/usersSlice";
import booksReducer from "./features/books/bookSlice";
import pagesReducer from "./features/pages/pageSlice";
import analyticsReducer from "./features/analytics/analyticsSlice";


export const store = configureStore({
  reducer: {
    auth: authReducer,
    staff: staffReducer,
    users: usersReducer,
    books: booksReducer,
    pages: pagesReducer,
    analytics: analyticsReducer,
  },
});
