import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bookServices from "./bookServices";

const bookSlice = createSlice({
  name: "books",
  initialState: {
    books: [],
    book: [],
    isError: false,
    isSuccess: false,
    isCreated: false,
    isLoading: false,
    isCreating: false,
    message: "",
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isCreated = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBooks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBooks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isCreated = false;
        state.books = payload;
      })
      .addCase(getBooks.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.books = [];
      })
      .addCase(getBook.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBook.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isCreated = false;
        state.book = payload;
      })
      .addCase(getBook.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.book = [];
      })
      .addCase(addBook.pending, (state) => {
        state.isCreating = true;
      })
      .addCase(addBook.fulfilled, (state, action) => {
        state.isCreating = false;
        state.isCreated = true;
        state.isError = false;
        state.message = action.payload;
      })
      .addCase(addBook.rejected, (state, action) => {
        state.isCreating = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// Get all Books
export const getBooks = createAsyncThunk("books", async (thunkAPI) => {
  try {
    return await bookServices.getBooks();
  } catch (error) {
    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});
// Get all Books
export const getBook = createAsyncThunk("book", async (id, thunkAPI) => {
  try {
    return await bookServices.getBook(id);
  } catch (error) {
    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add a book
export const addBook = createAsyncThunk("add/book", async (data, thunkAPI) => {
  try {
    return await bookServices.addBook(data);
  } catch (error) {

    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const { reset } = bookSlice.actions;

export default bookSlice.reducer;
