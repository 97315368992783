import { Empty } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import LoadingModal from "../modal/LoadingModal";
import UserTable from "../shared/UserTable";
import StatusPill from "./StatusPill";

const UsersTable = ({ staff }) => {
  const { isApproving } = useSelector((state) => state.users);

  const columns = useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Gender",
        accessor: "sex",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "mobileNumber",
      },
      {
        Header: "Type",
        accessor: "usertype",
      },
      {
        Header: "Status",
        accessor: "approvalStatus",
        Cell: StatusPill,
      }
    ],
    []
  );

  // Store data in the memo
  const data = useMemo(() => staff, [staff]);

  return (
    <>
      <LoadingModal isApproving={isApproving} />
      {data && data.length === 0 ? (
        <div className="min-h-[70vh] flex items-center justify-center">
          <Empty />
        </div>
      ) : (
        <div className="mt-4">
          <UserTable columns={columns} data={data} />
        </div>
      )}
    </>
  );
};

export default UsersTable;
