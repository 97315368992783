import React from "react";
import { BiCheckDouble } from "react-icons/bi";

const Reads = ({ value }) => {
  return (
    <div className="flex items-center">
      <div className="bg-gray-100 p-2 w-fit rounded-full">
        <BiCheckDouble color="#1fe61f"/>
      </div>
      <p className="ml-2">{value}</p>
    </div>
  );
};

export default Reads;
