import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import pageServices from "./pageServices";

const pageSlice = createSlice({
  name: "books",
  initialState: {
    pages: [],
    isError: false,
    isSuccess: false,
    isCreated: false,
    isLoading: false,
    message: "",
  },
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.isCreated = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookPages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBookPages.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isCreated = false;
        state.pages = payload;
      })
      .addCase(getBookPages.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.pages = [];
      })
      .addCase(addBookPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addBookPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isCreated = true;
        state.isError = false;
        state.message = action.payload;
      })
      .addCase(addBookPage.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

// Get all Book Pages
export const getBookPages = createAsyncThunk("book/pages", async (thunkAPI) => {
  try {
    return await pageServices.getBookPages();
  } catch (error) {
    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Add a book page
export const addBookPage = createAsyncThunk("add/book/page", async (data, thunkAPI) => {
  try {
    return await pageServices.addBookPage(data);
  } catch (error) {
    const message =
      error.response.data.errors ||
      error.response.data.status ||
      error.response.data.message;
    error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const { reset } = pageSlice.actions;

export default pageSlice.reducer;
