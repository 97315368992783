import moment from "moment";

const DateFormater = ({ value }) => {
  return (
    <div>
      <p className="text-sm">{moment(value).format("YYYY, MMM DD")}</p>
    </div>
  );
};

export default DateFormater;
