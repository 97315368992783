import API from "../../../api/api";
import { approveEndpoint, getUsersEndpoint } from "../../../constants";
import { notification } from "../../../utils";

// Get all Users
const getUsers = async () => {
  const response = await API.get(getUsersEndpoint);
  return response.data;
};

// Approving a User
const approve = async (id) => {
  const response = await API.get(`${approveEndpoint}/${id}}`);
  if (response.status === 200) {
    notification(response.data.message, "success", "bottomLeft");
  } else {
    notification("Something went wrong!", "error", "bottomLeft");
  }
  return response.data.message;
};

const usersServices = {
  getUsers,
  approve
};

export default usersServices;
