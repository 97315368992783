import moment from "moment";
import React from "react";
import { HiUserCircle } from "react-icons/hi";
import { useSelector } from "react-redux";

const Account = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="px-10 min-h-[80vh] flex items-center justify-center">
      <div className="h-fit">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-3/5">
            <h3 className="font-bold mt-8 mb-4 text-[18px]">
              User Information
            </h3>
            <p className="text-gray-700">
              Here you can see information about yourself. <br /> In case you
              need to modify them contact the system owner{" "}
            </p>
            <form className="mt-4 space-y-6">
              <div>
                <label className="form-label">Email Adress</label>
                <input
                  type="text"
                  value={user?.userDetails?.email}
                  className="account-form-input"
                  disabled
                />
              </div>
              <div>
                <label className="form-label">Full Name</label>
                <div className="flex flex-col md:flex-row items-center justify-between space-x-5">
                  <input
                    type="text"
                    value={user?.userDetails?.firstName}
                    className="account-form-input"
                    disabled
                  />
                  <input
                    type="text"
                    value={user?.userDetails?.lastName}
                    className="account-form-input"
                    disabled
                  />
                </div>
              </div>
              <div>
                <label className="form-label">Address</label>
                <div className="flex flex-col md:flex-row items-center justify-between space-x-5">
                  <input
                    type="text"
                    value={
                      user?.userDetails?.country === null
                        ? "no address set"
                        : user?.userDetails?.country
                    }
                    className="account-form-input"
                    disabled
                  />
                  <input
                    type="text"
                    value={
                      user?.userDetails?.province === null
                        ? "no province set"
                        : user?.userDetails?.province
                    }
                    className="account-form-input"
                    disabled
                  />
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between space-x-5">
                  <input
                    type="text"
                    value={
                      user?.userDetails?.district === null
                        ? "no district set"
                        : user?.userDetails?.district
                    }
                    className="account-form-input"
                    disabled
                  />
                  <input
                    type="text"
                    value={
                      user?.userDetails?.sector === null
                        ? "no sector set"
                        : user?.userDetails?.sector
                    }
                    className="account-form-input"
                    disabled
                  />
                </div>
                <div className="flex flex-col md:flex-row items-center justify-between space-x-5">
                  <input
                    type="text"
                    value={
                      user?.userDetails?.cell === null
                        ? "no cell set"
                        : user?.userDetails?.cell
                    }
                    className="account-form-input"
                    disabled
                  />
                  <input
                    type="text"
                    value={
                      user?.userDetails?.village === null
                        ? "no village set"
                        : user?.userDetails?.village
                    }
                    className="account-form-input"
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="w-full ml-0 md:ml-14 md:w-2/5">
            <div className="flex justify-center mt-12">
              {user?.userDetails?.profileImage !== null ? (
                <img
                  src={user?.userDetails?.profileImage}
                  className="w-[150px] h-[150px] rounded-full"
                  alt="profile"
                />
              ) : (
                <div>
                  <HiUserCircle size={150} className="text-gray-400" />
                </div>
              )}
            </div>
            <div className="text-center">
              <p className="font-bold">
                {user?.userRole === "superAdmin" ? "Super Admin" : "Staff"}
              </p>
            </div>
            <div className="text-center">
              <p className="text-[14px] text-gray-600">
                {user?.userDetails?.mobileNumber}
              </p>
            </div>
            <div className="mt-5 flex justify-center">
              <div className="text-center">
                <h3 className="font-bold">Date of Account Creation</h3>
                <p className="text-gray-600 pt-2 text-[14px]">
                  This Account was created by <br />
                  <span className="font-bold">
                    Education Development Consult
                  </span>{" "}
                  <br /> on the{" "}
                  {moment(user?.userDetails?.created_at).format(
                    "MMMM Do YYYY, h:mm:ss a"
                  )}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
