import React from "react";
import { MdOutlineCancel } from "react-icons/md";
import { staffLinks } from "../../constants";
import { fullLogo } from "../../assets";

const StaffSidebar = ({ activeMenu, setActiveMenu, index, setIndex }) => {
  const activeLink =
    "bg-gray-200 cursor-disabled flex items-center space-x-5 px-4 pt-3 pb-2.5 rounded-lg  text-primary  text-md m-2 cursor-pointer";
  const normalLink =
    "flex items-center space-x-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 hover:text-primary hover:bg-gray-200 m-2 cursor-pointer";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <div className="items-center space-x-3 ml-3 mt-4 flex text-md font-[Gordita-Bold] tracking-tight text-primary">
              <img src={fullLogo} alt="edc-logo" width={"60%"} />
            </div>

            <button
              type="button"
              onClick={() => setActiveMenu(!activeMenu)}
              className="text-primary text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden">
              <MdOutlineCancel />
            </button>
          </div>
          <div className="mt-10 ">
            {staffLinks.map((item) => (
              <div key={item.title}>
                <p className="text-dark font-bold mx-3 my-6 uppercase text-[14px]">
                  {item.title}
                </p>
                {item.links.map((link) => {
                  return (
                    <div
                      key={link.name}
                      onClick={() => setIndex(link.id)}
                      className={link.id === index ? activeLink : normalLink}>
                      {link.icon}
                      <span className="capitalize hover:text-primary">
                        {link.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default StaffSidebar;
