import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { lsUser } from "../../../constants";
import authService from "./authServices";

// Get token from LS
const user = JSON.parse(localStorage.getItem(lsUser));

// Initial State for the auth Slice
const initialState = {
  user: user ? user : null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// reset state
const resetState = {
  isLoading: false,
  isError: false,
  message: "",
};

// Auth slice
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => resetState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = payload;
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.user = null;
      })
      .addCase(forgotpw.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotpw.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = payload;
      })
      .addCase(forgotpw.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.token = null;
      })
      .addCase(resetpw.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetpw.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = payload;
      })
      .addCase(resetpw.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.token = null;
      })
      .addCase(changepw.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changepw.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.token = payload;
      })
      .addCase(changepw.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isError = true;
        state.message = payload;
        state.token = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
      });
  },
});

// Login
export const login = createAsyncThunk("staff/login", async (data, thunkAPI) => {
  try {
    return await authService.login(data);
  } catch (error) {
    let message;
    if (error.code === "ERR_NETWORK") {
      message = error.message;
    } else if (error.response) {
      if (error.response.data.message) {
        message = error.response.data.message;
      } else {
        message = error.response.data.userName[0];
      }
    }
    return thunkAPI.rejectWithValue(message);
  }
});

// Forgot password
export const forgotpw = createAsyncThunk(
  "staff/forgotpw",
  async (data, thunkAPI) => {
    try {
      return await authService.forgotpw(data);
    } catch (error) {
      let message;
      if (error.code === "ERR_NETWORK") {
        message = error.message;
      } else if (error.response) {
        message = error.response.data.errors;
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// reset password
export const resetpw = createAsyncThunk(
  "staff/resetpassword",
  async (data, thunkAPI) => {
    try {
      return await authService.resetpw(data);
    } catch (error) {
      let message;
      if (error.code === "ERR_NETWORK") {
        message = error.message;
      } else if (error.response) {
        message = error.response.data.mesage;
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// change password
export const changepw = createAsyncThunk(
  "staff/changepassword",
  async (data, thunkAPI) => {
    try {
      return await authService.changepw(data);
    } catch (error) {
      let message;
      if (error.code === "ERR_NETWORK") {
        message = error.message;
      } else if (error.response) {
        message = error.response.data.message;
      }
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Logout
export const logout = createAsyncThunk("logout", async () => {
  await authService.logout();
});

export const { reset, resetErrors } = authSlice.actions;
export default authSlice.reducer;
