import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import analyticsService from "./analyticsService";

const analyticsSlice = createSlice({
	name: "analytics",
	initialState: {
		analytics: [],
		isError: false,
		isSuccess: false,
		isLoading: false,
		message: "",
	},
	reducers: {
		reset: (state) => {
			state.isLoading = false;
			state.isSuccess = false;
			state.isError = false;
			state.message = "";
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAnalytics.pending, (state) => {
				state.isLoading = true;
			})
			.addCase(getAnalytics.fulfilled, (state, { payload }) => {
				state.isLoading = false;
				state.isSuccess = true;
				state.isCreated = false;
				state.analytics = payload;
			})
			.addCase(getAnalytics.rejected, (state, { payload }) => {
				state.isLoading = false;
				state.isError = true;
				state.message = payload;
				state.analytics = [];
			});
	},
});

// Get all Analytics
export const getAnalytics = createAsyncThunk("get/analytics", async (thunkAPI) => {
	try {
		return await analyticsService.getAnalytics();
	} catch (error) {
		console.lot("analytics", error)
		const message =
			error.response.data.errors ||
			error.response.data.status ||
			error.response.data.message;
		error.toString();
		return thunkAPI.rejectWithValue(message);
	}
});


export const { reset } = analyticsSlice.actions;

export default analyticsSlice.reducer;
