import API from "../../../api/api";
import {
  changepwEndpoint,
  forgotpwEndpoint,
  loginEndpoint,
  lsUser,
  resetpwEndpoint,
} from "../../../constants";
import { notification } from "../../../utils";

// utils

// login
const login = async (data) => {
  const response = await API.post(loginEndpoint, data);
  if (response.data) {
    localStorage.setItem(lsUser, JSON.stringify(response.data));
  }
  return response.data;
};

// forgot password
const forgotpw = async (data) => {
  const response = await API.post(forgotpwEndpoint, data);
  return response.data;
};

// reset password
const resetpw = async (data) => {
  // Get token from param
  const token =
    "20OjixX2SntdjnRalAValgxpvcIuyo0KmzFozmYet7n11sH2E1cMJDyu0demIsHLd1YRM53vVfIJhxjl2po7PjsDF2S4d9B18s6fIqqZZq3SD0F8gx12COQeRPla";
  const response = await API.post(`${resetpwEndpoint}/${token}`, data);
  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data)); // This will make it possible to have it as login
  }
  return response.data;
};

// change password
const changepw = async (data) => {
  const response = await API.post(changepwEndpoint, data);
  if (response.data) {
    notification(response.data.message, "success", "bottomLeft");
  }
  return response.data;
};

// logout Service
const logout = () => {
  localStorage.removeItem(lsUser);
};

const authService = {
  login,
  logout,
  forgotpw,
  resetpw,
  changepw,
};

export default authService;
